.status-badge {
    height: 20px;
    width: 20px;
    border: 2px solid whitesmoke;
    border-radius: 50%;
    margin: 0 auto;
}

.success {
    background-color: green;
}

.danger {
    background-color: red;
}

.screen-table {
    /* margin: 20px auto; */
    width: 100%;
}

.button-controlls {
    height: 100%;
    width: 100%;
    /* border:1px solid black; */
}

.add-screen-btn {
    height: 50px;
    width: 100%;
    background-color: #551fff;
    color: white;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.btn-1 {
    height: 50px;
    width: 50%;
    margin: 5px 5px 0 0;
    background-color: white;
    border: 1px solid #551fff;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.btn-2 {
    height: 50px;
    width: 50%;
    margin: 5px 0 0 0;
    background-color: white;
    border: 1px solid #551fff;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

/* .screen-form{
    height:35vh;
    width:30vw;
} */
.icon-screen {
    color: #551fff;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-screen .screen-icon {
    height: 100%;
    width: 100%;
}
.control--btn{
    height: 30px;
    width: 30px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.mobile--container{
    min-height: 80vh;
    width: 58.5vw;
    margin:0 auto;
    /* border:1px solid black; */
}
.mobile--container--header{
    min-height:7vh;
    width:100%;
    /* border:1px solid black; */
    display: flex;
    justify-content: right;
    align-items:flex-start;
    gap:10px;
}
.mobile--container--body{
    min-height:calc(80vh - 7vh);
    width:100%;
    /* border:1px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: ; */
    align-items: flex-end;
}
/* @media only screen and (max-width:1980px){
    .mobile--container--body{
        align-items:flex-end;
    }
}
@media only screen and (max-width:1900px){
    .mobile--container--body{
        align-items:flex-end;
    }
}
@media only screen and (max-width:1480px){
    .mobile--container--body{
        align-items:flex-end;
    }
} */
button{
    background-color: #551fff;
    color:white;
    height:40px;
    width:80px;
    text-transform: uppercase;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
}
input{
    background-color: transparent;
    border: 4px solid #551fff;
    height:30px;
    border-radius: 5px;
    outline: none;
}
.mobile--screen--footer{
    height:40px;
    margin:5px 0;
    width:800px;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
    align-items:center;
}
.last-update-txt{
    width:50%;
    
}
.screen-quality{
    width:50%;
    display: flex;
    justify-content: flex-end;
    align-items:center;
}