body {
  font-family: 'Open Sans', sans-serif !important;
}

.parent-container {
  max-height: 85vh;
  width: 95%;
  margin: 0 auto;
  /* border:1px solid black; */
}

.child-container {
  height: 15vh;
  width: 100%;
  /* border:1px solid yellow; */
  display: flex;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.alignItemCenter {
  align-items: center;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyCententEnd {
  justify-content: end;
}

.flex-1 {
  flex: 1;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-around-between {
  justify-content: space-around;
}

.main-color {
  color: #551fff;
}

.left {
  width: 70%;
  height: 100%;
  /* border-right: 1px solid black; */
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  background-color: white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  border-radius: 20px;
}

.right {
  width: 30%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.margin-left-5 {
  margin-left: 5px;
}

#SvgjsText4244,
#SvgjsText4126 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px;
}

.MuiDialogTitle-root {
  font-family: 'Open Sans', sans-serif !important;
}

.background-red {
  background-color: red;
}

.background-blue {
  background-color: blue;
}

.customInput input::-webkit-input-placeholder {
  font-size: 15px !important;
  color: red !important;
}
