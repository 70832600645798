.table-background {
    background-color: #ffffff;
    border-radius: 24px;
}

.table {
    margin: 20px auto;
}

.search-box {
    display: flex;
}

.search-box .search-icon {
    margin: 0 32px 0 20px !important;
}

.user-add-btn {
    width: 273px;
    height: 58px;
    /* border:1px solid black; */
    margin: 0 20px;
}

.dialogBorder {
    border-radius: 16px !important;
}

.user-add-btn button {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: #551FFF;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}

.user-add-form-container {
    width: 30vw;
    /* height: 60vh; */
    display: flex;
    flex-direction: column;
}

.user-add-form-container .form-fields {
    height: 30;
    width: 90%;
    /* border:1px solid black; */
    margin: 10px auto;
}

.add-user-form-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0 0 0;
}

.add-user-form-submit-btn button {
    width: 215px;
    height: 40px;
    background: #551FFF;
    border: 1px solid #551FFF;
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
