.layout-container {
    min-height: 100vh;
    max-width: 100vw;
    background: #E5E5E5;
    display: flex;
}

.side-bar {
    height: 100vh;
    width: 18vw;
    background-color: #FFFFFF;
    /* box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.2),
        -1px -1px 5px 5px rgba(0, 0, 0, 0.2); */
    /* display: none; */
}

.body {
    flex: 1;
    height: 100vh;
    width: 80vw;
    background: #f0f3fb;
    display: flex;
    flex-direction: column;
    /* border:1px solid black; */
}

.body .body-header {
    height: 13%;
    width: 100%;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
}

.body-header-left {
    width: 40%;
    /* height: 100%; */
    /* border:1px solid black; */
    display: flex;
    align-items: center;
}

.body-header-right {
    width: 60%;
    height: 100%;
    /* border: 1px solid black; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 21px;
}

.body-header-right-inner {
    /* height: 70%;
    width: 100%; */
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

.body-header-right-inner .time-day {
    display: flex;
    align-items: center;
    justify-content: center;
}

.body-header-right-inner .account {
    display: flex;
    margin: 0 10px;
}

.account .account-menu {
    color: #6B7A99;
}

.body-header-right-inner .notification {
    background: #FFFFFF;
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    border-radius: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body-header .body-header-left .titles {
    /* height: 15%; */
    /* width: 100%; */
    display: flex;
    /* margin: 20px 0; */
}

.title-logo {
    width: 36px;
    height: 39px;
    background: #551FFF;
    border-radius: 0px 21px 21px 0px;
}

.form-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #313031;
    /* margin-top: -7px; */
    margin: 0 !important;
    margin-left: 10px !important;
}

.body .body-body {
    height: 90vh;
    width: 100%;
    /* border:1px solid black; */
}

.body-body .body-body-scroll-able {
    max-height: 85vh;
    max-width: 100vw;
    margin: 5px 0px;
    /* border:1px solid green; */
    overflow-y: auto;
}

.body-body-scroll-able::-webkit-scrollbar {
    display: block;
}

.body-body-scroll-able::-webkit-scrollbar-track {
    width: 5px;
}

.body-body-scroll-able::-webkit-scrollbar-thumb {
    background-color: #551FFF;
    border-radius: 5px;
}

.upgradePro {
    background: #551FFF;
    border-radius: 6px;
    border: none;
    height: 30px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
  }